import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import {
  Stepper,
  StepLabel,
  StepContent,
  Step,
  Button,
  Paper,
  Grid,
  TextField,
  InputAdornment,

} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Container from 'components/Container';

const VerticalLinearStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [employeeCount, setEmployeeCount] = React.useState({
    2020: 0,
    2021: 0
  })

  const [averageYearlyWage, setAverageYearlyWage] = React.useState({
    2020: 0,
    2021: 0
  })

  const steps = [
    {
      label: 'How many employees did you have in 2020 and at present (Average Estimate)',
      field: 'employeeCount',
      helperText: 'Average employee count'
    },
    {
      label: 'Average yearly wage per employee',
      field: 'averageYearlyWage',
      helperText: 'Average yearly wage'
    },
    {
      label: 'Address',
      value: 'Via Venini 33, 20147',
    },
  ];

  const handleChange = (e) => {
    const target = e.target;
    switch (target.id) {
      case "employeeCount-2020":
        setEmployeeCount({ ...employeeCount, 2020: target.value })
        break;
      case "employeeCount-2021":
        setEmployeeCount({ ...employeeCount, 2021: target.value })
        break;
      case "averageYearlyWage-2020":
        setAverageYearlyWage({...averageYearlyWage, 2020: target.value})
        break;
      case "averageYearlyWage-2021":
        setAverageYearlyWage({...averageYearlyWage, 2021: target.value})
        break;
      default:
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box maxWidth={600}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.field}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Container>
                {
                  step.field === 'employeeCount' &&
                  <Grid container spacing={2}>
                    <Grid item xs="6">
                      <TextField
                        variant="outlined"
                        id={step.field + '-2020'}
                        required
                        value={employeeCount['2020']}
                        onChange={handleChange}
                        label={"2020"} 
                        helperText={step.helperText}/>

                    </Grid>
                    <Grid item xs="6">
                      <TextField
                        variant="outlined"
                        id={step.field + '-2021'}
                        required
                        value={employeeCount['2021']}
                        onChange={handleChange}
                        label={"2021"} 
                        helperText={step.helperText}/>
                    </Grid>
                  </Grid>
                }
                {
                  step.field === 'averageYearlyWage' &&
                  <Grid container spacing={2}>
                    <Grid item xs="6">
                      <TextField
                        variant="outlined"
                        id={step.field + '-2020'}
                        required
                        value={averageYearlyWage['2020']}
                        onChange={handleChange}
                        label={"2020"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AttachMoneyRoundedIcon />
                            </InputAdornment>
                          ),
                        }} />
                    </Grid>
                    <Grid item xs="6">
                      <TextField
                        fontSize="small"
                        variant="outlined"
                        id={step.field + '-2021'}
                        required
                        value={averageYearlyWage['2021']}
                        onChange={handleChange}
                        label={"2021"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AttachMoneyRoundedIcon />
                            </InputAdornment>
                          ),
                        }} />
                    </Grid>
                  </Grid>
                }
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {index === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
                {index > 0 && <Button
                  size="small"
                  variant="outlined"
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>}
              </Container>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
const RefundCalculator = () => {
  const theme = useTheme();

  const LeftSide = () => {
    return (
      <Box>
        <Box marginBottom={2}>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
            Eligibility & Refund Calculator
          </Typography>
          <Typography color="text.secondary">
            Take a few minutes to discover if you are eligible and get a quick look
            at just how much money is GUARANTEED to you via the Employee Retention Credit (ERC).
          </Typography>
          <Typography color="text.secondary">
            Unlike PPP, this is not a loan! ERC is a refund of money you paid in payroll taxes
            to keep employees on payroll during the pandemic. This is YOUR money.
          </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <VerticalLinearStepper />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            width={1}
            order={{ xs: 2, md: 1 }}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default RefundCalculator;
